import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CheckSnackbars } from '../types/check-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class CheckSnackbarsService {
  constructor(private snackbar: MatSnackBar) {}

  public successRegisterCheck(): void {
    this.snackbar.open(
      CheckSnackbars.successRegisterCheck.message,
      CheckSnackbars.successRegisterCheck.closeBtn,
      CheckSnackbars.successRegisterCheck.config
    );
  }

  public successSentReportByEmail(): void {
    this.snackbar.open(
      CheckSnackbars.successSentReportByEmail.message,
      CheckSnackbars.successSentReportByEmail.closeBtn,
      CheckSnackbars.successSentReportByEmail.config
    );
  }

  public successDeleteCheckEmployee(): void {
    this.snackbar.open(
      CheckSnackbars.successDeleteCheckEmployee.message,
      CheckSnackbars.successDeleteCheckEmployee.closeBtn,
      CheckSnackbars.successDeleteCheckEmployee.config
    );
  }

  public failureInvalidField(): void {
    this.snackbar.open(
      CheckSnackbars.failureInvalidField.message,
      CheckSnackbars.failureInvalidField.closeBtn,
      CheckSnackbars.failureInvalidField.config
    );
  }

  public failureEmployeeInvalidCredentials(): void {
    this.snackbar.open(
      CheckSnackbars.failureEmployeeInvalidCredentials.message,
      CheckSnackbars.failureEmployeeInvalidCredentials.closeBtn,
      CheckSnackbars.failureEmployeeInvalidCredentials.config
    );
  }

  public failureWrongHours(): void {
    this.snackbar.open(
      CheckSnackbars.failureWrongHours.message,
      CheckSnackbars.failureWrongHours.closeBtn,
      CheckSnackbars.failureWrongHours.config
    );
  }

  public failureEmployeeNotWithInWorkplaceAccessRadius(): void {
    this.snackbar.open(
      CheckSnackbars.failureEmployeeNotWithInWorkplaceAccessRadius.message,
      CheckSnackbars.failureEmployeeNotWithInWorkplaceAccessRadius.closeBtn,
      CheckSnackbars.failureEmployeeNotWithInWorkplaceAccessRadius.config
    );
  }

  public failureCheckDateCollidesWithAnExistingOne(): void {
    this.snackbar.open(
      CheckSnackbars.failureCheckDateCollidesWithAnExistingOne.message,
      CheckSnackbars.failureCheckDateCollidesWithAnExistingOne.closeBtn,
      CheckSnackbars.failureCheckDateCollidesWithAnExistingOne.config
    );
  }

  public failureCheckCalendarIsClosed(): void {
    this.snackbar.open(
      CheckSnackbars.failureCheckCalendarIsClosed.message,
      CheckSnackbars.failureCheckCalendarIsClosed.closeBtn,
      CheckSnackbars.failureCheckCalendarIsClosed.config
    );
  }

  public failureEmployeeOnLeave(): void {
    this.snackbar.open(
      CheckSnackbars.failureEmployeeOnLeave.message,
      CheckSnackbars.failureEmployeeOnLeave.closeBtn,
      CheckSnackbars.failureEmployeeOnLeave.config
    );
  }
}
