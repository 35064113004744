import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';
import { Check } from '../types/check';

@Injectable({
  providedIn: 'root',
})
export class CheckWidgetHttpService {
  constructor(private http: HttpClient) {}

  // ==========================================================================================
  // CREATE
  // ==========================================================================================

  public toggleCheck$(
    employeeID: number,
    check: Check
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks`;
    return this.http.post(url, check, { observe: 'response' });
  }

  // ==========================================================================================
  // READ
  // ==========================================================================================

  public getJourney$(
    employeeID: number,
    params: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks/journeys/actives/temps`;
    return this.http.get(url, { observe: 'response', params });
  }
}
