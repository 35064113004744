import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config/snackbars/snackbar.config';

export const CompanySnackbars = {
  successRegister: {
    message: `Empresa agregada`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },

  successUpdated: {
    message: `Información de la empresa actualizada`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },

  successAddressUpdated: {
    message: `Dirección de la empresa actualizada`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },

  failureValidationError: {
    message: `Falta un campo obligatorio`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
};
