import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[qaroniFilesDragAndDrop]',
})
export class FilesDragAndDropDirective {
  @Output() private files: EventEmitter<FileList> = new EventEmitter();

  @HostBinding('style.background') private background;
  @HostBinding('style.border-style') private borderStyle;
  @HostBinding('style.border-color') private borderColor;

  @HostListener('dragover', ['$event']) public onDragover(event: DragEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    this.setDragStyles();
  }

  @HostListener('dragleave', ['$event']) public onDragleave(event: DragEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    this.resetStyles();
  }

  @HostListener('drop', ['$event']) public onDrop(event: DragEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    this.resetStyles();

    if (!event?.dataTransfer?.files?.length) {
      return;
    }

    this.files.emit(event?.dataTransfer?.files);
  }

  constructor() {
    this.resetStyles();
  }

  private setDragStyles() {
    this.background = '#e2f1fd';
    this.borderStyle = 'dashed';
    this.borderColor = '#0c7dd6';
  }

  private resetStyles() {
    this.background = 'transparent';
    this.borderStyle = 'dotted';
    this.borderColor = '#82c4f8';
  }
}
