import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';
import { Check } from '../types/check';
import { Month } from '../types/period';

@Injectable({
  providedIn: 'root',
})
export class CheckHttpService {
  constructor(private http: HttpClient) {}

  // ==========================================================================================
  // CREATE
  // ==========================================================================================

  public registerCheck$(
    employeeID: number,
    check: Check
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks/journeys`;
    return this.http.post(url, check, { observe: 'response' });
  }

  // ==========================================================================================
  // READ
  // ==========================================================================================

  public getChecks$(
    employeeID: number,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks`;
    return this.http.get(url, { observe: 'response', params });
  }

  public getCheck$(
    employeeID: number,
    checkId: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks/${checkId}`;
    return this.http.get(url, { observe: 'response' });
  }

  // ==========================================================================================
  // UPDATE
  // ==========================================================================================

  public updateCheck$(
    employeeID: number,
    checkID: number,
    check: Check
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks/journeys/${checkID}`;
    return this.http.patch(url, check, { observe: 'response' });
  }

  // ==========================================================================================
  // DELETE
  // ==========================================================================================

  public deleteCheck$(
    employeeID: number,
    checkID: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks/${checkID}`;
    return this.http.delete(url, { observe: 'response' });
  }

  // ==========================================================================================
  // UTILS
  // ==========================================================================================

  public sendChecksReport$(
    employeeID: number,
    month: Month
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeID}/checks/pdf`;
    return this.http.patch(url, month, { observe: 'response' });
  }
}
