<mat-card
  @enterFadeIn
  class="qaroni-bg-surface qaroni-surface-border mat-elevation-z0"
  *ngIf="company()">
  <div class="mat-card-header">
    <div class="flex flex-1 items-center">
      <mat-card-title>{{ company()?.name }}</mat-card-title>
    </div>
    <a
      class="qaroni-link-on-surface flex items-center"
      [routerLink]="goToUpdateCompany"
      i18n>
      Editar
    </a>
  </div>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div class="flex w-full items-center justify-between gap-3">
      <div>
        <img
          class="aspect-square h-auto w-full"
          src="assets/img/profile_icon_8.svg"
          alt="Company profile" />
      </div>

      <div class="s grid flex-1 grid-flow-row grid-cols-12 gap-2">
        <div class="col-span-6" *ngIf="company()?.address">
          <qaroni-address-string
            [address]="company()?.address"></qaroni-address-string>
        </div>
        <div class="col-span-6" *ngIf="company()?.phone">
          <qaroni-label-phone-preview
            [phone]="company()?.phone"
            label></qaroni-label-phone-preview>
        </div>
        <div class="col-span-6" *ngIf="company()?.email">
          <qaroni-label-email-preview
            [email]="company()?.email"
            label></qaroni-label-email-preview>
        </div>
        <div class="col-span-6" *ngIf="company()?.nif">
          <qaroni-label-text-preview
            i18n-label
            label="Número de documento"
            [value]="company()?.nif"></qaroni-label-text-preview>
        </div>
        <div class="col-span-6" *ngIf="company()?.creationDate">
          <qaroni-label-text-preview
            i18n-label
            label="Fecha de alta"
            [value]="
              company()?.creationDate | date: 'medium'
            "></qaroni-label-text-preview>
        </div>
        <div class="col-span-6" *ngIf="company()?.lastUpdateDate">
          <qaroni-label-text-preview
            i18n-label
            label="Última actualización"
            [value]="
              company()?.lastUpdateDate | date: 'medium'
            "></qaroni-label-text-preview>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
