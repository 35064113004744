<mat-card
  @enterFadeIn
  class="qaroni-bg-surface qaroni-surface-border mat-elevation-z0">
  <div class="mat-card-header">
    <div class="flex w-full flex-1 items-center px-3">
      <mat-card-title i18n>Fichaje</mat-card-title>
      <mat-card-subtitle @enterFadeIn *ngIf="journey?.isOpen" i18n
        >en curso</mat-card-subtitle
      >
    </div>
  </div>

  <mat-divider class="mx-2"></mat-divider>

  <mat-card-content class="flex w-full items-center justify-between gap-1 py-3">
    <div
      [class]="cardDesign?.classBgStatus"
      [matTooltip]="journey?.isOpen ? 'En curso' : 'Detenido'"
      class="qaroni-status-indicator"></div>
    <div class="flex-1 text-center">
      <span class="journey-hours-text leading-none">
        {{ journey?.totalJourney | totalMinutesFormat }}
      </span>
      <p class="font-light" i18n>Jornada de hoy</p>
    </div>
    <mat-icon
      class="qaroni-text-neutral-to-primary"
      [matTooltip]="infoTooltip"
      matTooltipClass="qaroni-info-tooltip"
      >info</mat-icon
    >
  </mat-card-content>

  <mat-card-actions class="m-0 flex justify-center py-3">
    <button
      mat-flat-button
      [color]="cardDesign?.colorButton"
      class="qaroni-rounded-button"
      (click)="onCheck()"
      [disabled]="isLoading$ | async">
      <ng-container *ngIf="(isLoading$ | async) === false; else loading">
        <mat-icon class="me-1">{{ cardDesign?.iconButton }}</mat-icon>
        {{ cardDesign?.textButton }}
      </ng-container>
    </button>
  </mat-card-actions>
</mat-card>

<ng-template #loading>
  <mat-spinner [diameter]="30" class="mx-auto"></mat-spinner>
</ng-template>
