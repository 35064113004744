import { QaroniCache } from '@qaroni-core/types/qaroni-cache/qaroni-cache';
import { Workplace } from './workplace';

export class WorkplaceCache extends QaroniCache<Workplace> {
  public canReturn(companyID: number, workplaceID: number): boolean {
    return this.get() &&
      Number.isInteger(companyID) &&
      Number.isInteger(workplaceID) &&
      this.get().companyId === companyID &&
      this.get().workplaceId === workplaceID
      ? true
      : false;
  }

  public canApi(companyID: number, workplaceID: number): boolean {
    return !this.canReturn(companyID, workplaceID) && !this.flying
      ? true
      : false;
  }
}
