import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CheckWidgetSnackbars } from '../types/check-widget-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class CheckWidgetSnackbarsService {
  constructor(private snackbar: MatSnackBar) {}

  public failureInvalidField(): void {
    this.snackbar.open(
      CheckWidgetSnackbars.failureInvalidField.message,
      CheckWidgetSnackbars.failureInvalidField.closeBtn,
      CheckWidgetSnackbars.failureInvalidField.config
    );
  }

  public failureEmployeeInvalidCredentials(): void {
    this.snackbar.open(
      CheckWidgetSnackbars.failureEmployeeInvalidCredentials.message,
      CheckWidgetSnackbars.failureEmployeeInvalidCredentials.closeBtn,
      CheckWidgetSnackbars.failureEmployeeInvalidCredentials.config
    );
  }

  public failureEmployeeNotWithInWorkplaceAccessRadius(): void {
    this.snackbar.open(
      CheckWidgetSnackbars.failureEmployeeNotWithInWorkplaceAccessRadius
        .message,
      CheckWidgetSnackbars.failureEmployeeNotWithInWorkplaceAccessRadius
        .closeBtn,
      CheckWidgetSnackbars.failureEmployeeNotWithInWorkplaceAccessRadius.config
    );
  }

  public failureCheckCalendarIsClosed(): void {
    this.snackbar.open(
      CheckWidgetSnackbars.failureCheckCalendarIsClosed.message,
      CheckWidgetSnackbars.failureCheckCalendarIsClosed.closeBtn,
      CheckWidgetSnackbars.failureCheckCalendarIsClosed.config
    );
  }
}
