<mat-card
  @enterFadeIn
  class="qaroni-bg-surface qaroni-surface-border mat-elevation-z0 h-full w-full"
  *ngIf="scoreboards$ | async as scoreboards">
  <div class="mat-card-header">
    <div class="flex w-full items-center">
      <mat-card-title i18n>Fichaje en curso</mat-card-title>
    </div>
  </div>

  <mat-divider></mat-divider>

  <mat-card-content class="px-0">
    <div class="flex w-full flex-row gap-2">
      <div
        class="working-card flex flex-1 flex-col items-center justify-center gap-1"
        [routerLink]="goToEmployees"
        [queryParams]="employeesOnlineParam">
        <div class="icon-qr-code">
          <mat-icon>qr_code_scanner</mat-icon>
        </div>
        <span class="score">{{ scoreboards?.openChecks ?? 0 }}</span>
        <p class="score-text mb-0">Trabajando</p>
      </div>

      <div
        class="out-card flex flex-1 flex-col items-center justify-center gap-1"
        [routerLink]="goToEmployees"
        [queryParams]="employeesOfflineParam">
        <div class="icon-snooze">
          <mat-icon>snooze</mat-icon>
        </div>
        <span class="score">{{ scoreboards?.closeChecks ?? 0 }}</span>
        <p class="score-text mb-0">Fuera</p>
      </div>

      <div
        class="total-card flex flex-1 flex-col items-center justify-center gap-1"
        [routerLink]="goToEmployees">
        <div class="icon-people">
          <mat-icon>people</mat-icon>
        </div>
        <span class="score">{{ scoreboards?.totalEmployees ?? 0 }}</span>
        <p class="score-text mb-0">Total</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
