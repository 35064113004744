import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkplaceSnackbars } from '../types/workplace-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class WorkplaceSnackbarsService {
  constructor(private snackbar: MatSnackBar) {}

  public successRegister(): void {
    this.snackbar.open(
      WorkplaceSnackbars.successRegister.message,
      WorkplaceSnackbars.successRegister.closeBtn,
      WorkplaceSnackbars.successRegister.config
    );
  }

  public successUpdated(): void {
    this.snackbar.open(
      WorkplaceSnackbars.successUpdated.message,
      WorkplaceSnackbars.successUpdated.closeBtn,
      WorkplaceSnackbars.successUpdated.config
    );
  }

  public successAddressUpdated(): void {
    this.snackbar.open(
      WorkplaceSnackbars.successAddressUpdated.message,
      WorkplaceSnackbars.successAddressUpdated.closeBtn,
      WorkplaceSnackbars.successAddressUpdated.config
    );
  }

  public failureInvalidTimezone(): void {
    this.snackbar.open(
      WorkplaceSnackbars.failureInvalidTimezone.message,
      WorkplaceSnackbars.failureInvalidTimezone.closeBtn,
      WorkplaceSnackbars.failureInvalidTimezone.config
    );
  }
}
