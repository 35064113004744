export abstract class QaroniCacheCollection<T = any> {
  protected elements: T[];

  protected flying: boolean;

  abstract canReturn(...attributes: any): boolean;

  abstract canApi(...attributes: any): boolean;

  abstract valid(el: T): boolean;

  abstract findIndex(el: T): number;

  public startToFly(): void {
    this.flying = true;
  }

  public endFlying(): void {
    this.flying = false;
  }

  public get(): T[] {
    return this.length() ? [...this.elements] : [];
  }

  public set(elements: T[]): void {
    if (!Array.isArray(elements)) {
      return;
    }

    if (this.elements?.length) {
      this.elements.length = 0;
    }

    this.elements = [...elements];
  }

  public length(): number {
    return this.elements?.length;
  }

  public first(): T {
    return Array.isArray(this.elements) && this.elements?.length
      ? this.elements[0]
      : null;
  }

  public add(el: T): void {
    if (!this.valid(el)) {
      return;
    }

    this.elements?.push(el);
  }

  public update(el: T): void {
    if (!this.valid(el)) {
      return;
    }

    const i: number = this.findIndex(el);

    if (i !== -1) {
      this.elements[i] = el;
    } else {
      this.elements?.push(el);
    }
  }

  public remove(el: T): void {
    if (!this.valid(el)) {
      return;
    }

    const i: number = this.findIndex(el);

    if (i !== -1) {
      this.elements?.splice(i, 1);
    }
  }

  public empty(): void {
    delete this.elements;
  }
}
