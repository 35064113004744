import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/auth/services/o-auth-storage.service';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { PaginationLinks } from '@qaroni-core/types/pagination-links/pagination-links';
import { Observable, Subject } from 'rxjs';
import { Check } from '../types/check';
import { Journey } from '../types/journey';
import { Month } from '../types/period';
import { CheckHttpService } from './check-http.service';
import { CheckSnackbarsService } from './check-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class CheckService {
  protected readonly checksSubject = new Subject<Check[]>();
  protected readonly checkSubject = new Subject<Check>();
  protected readonly journeySubject = new Subject<Journey>();
  protected readonly messageSubject = new Subject<string>();
  protected readonly paginationLinksSubject = new Subject<PaginationLinks>();

  constructor(
    private checkHttp: CheckHttpService,
    private commonsHttp: CommonsHttpService,
    private oAuthStorage: OAuthStorageService,
    private snackbars: CheckSnackbarsService
  ) {}

  // ==========================================================================================
  // Register Check
  // ==========================================================================================

  public getCheck$(): Observable<Check> {
    return this.checkSubject.asObservable();
  }

  public getJourney$(): Observable<Journey> {
    return this.journeySubject.asObservable();
  }

  public registerCheck(employeeID: number, check: Check): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.checkHttp.registerCheck$(employeeID, check).subscribe({
      next: this.nextRegisterCheck,
      error: this.errorRegisterCheck,
    });
  }

  private nextRegisterCheck = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus201(data)) {
      const check: Check = data.body.result[0];
      const journey: Journey = data.body.included;
      this.checkSubject.next(check);
      this.journeySubject.next(journey);
      this.snackbars.successRegisterCheck();
    } else {
      this.checkSubject.next(null);
      this.journeySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorRegisterCheck = (error: HttpErrorResponse): void => {
    this.checkSubject.next(null);
    this.journeySubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureWrongHours();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0008')) {
      this.snackbars.failureEmployeeInvalidCredentials();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0020')) {
      this.snackbars.failureEmployeeNotWithInWorkplaceAccessRadius();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0028')) {
      this.snackbars.failureCheckDateCollidesWithAnExistingOne();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0030')) {
      this.snackbars.failureCheckCalendarIsClosed();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Checks
  // ==========================================================================================

  public getChecks$(): Observable<Check[]> {
    return this.checksSubject.asObservable();
  }

  public getChecks(employeeID: number, params?: Params): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.checkHttp.getChecks$(employeeID, params).subscribe({
      next: this.nextGetChecks,
      error: this.errorGetChecks,
    });
  }

  private nextGetChecks = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const checks: Check[] = data.body.result;
      const paginationLinks: PaginationLinks = data.body.links;
      this.checksSubject.next(checks);
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.checksSubject.next(null);
      this.paginationLinksSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetChecks = (error: HttpErrorResponse): void => {
    this.checksSubject.next(null);
    this.paginationLinksSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Check
  // ==========================================================================================

  public updateCheck(employeeID: number, checkID: number, check: Check): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.checkHttp.updateCheck$(employeeID, checkID, check).subscribe({
      next: this.nextUpdateCheck,
      error: this.errorUpdateCheck,
    });
  }

  private nextUpdateCheck = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const check: Check = data.body.result[0];
      const journey: Journey = data.body.included;
      this.checkSubject.next(check);
      this.journeySubject.next(journey);
      this.snackbars.successRegisterCheck();
    } else {
      this.checkSubject.next(null);
      this.journeySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdateCheck = (error: HttpErrorResponse): void => {
    this.checkSubject.next(null);
    this.journeySubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureWrongHours();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0008')) {
      this.snackbars.failureEmployeeInvalidCredentials();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0020')) {
      this.snackbars.failureEmployeeNotWithInWorkplaceAccessRadius();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0028')) {
      this.snackbars.failureCheckDateCollidesWithAnExistingOne();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0030')) {
      this.snackbars.failureCheckCalendarIsClosed();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Delete Check
  // ==========================================================================================

  public getMessage$(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  public deleteCheck(employeeID: number, checkID: number): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.checkHttp.deleteCheck$(employeeID, checkID).subscribe({
      next: this.nextDeleteCheck,
      error: this.errorDeleteCheck,
    });
  }

  private nextDeleteCheck = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const journey: Journey = data.body.included;
      this.messageSubject.next('OK delete check');
      this.journeySubject.next(journey);
      this.snackbars.successDeleteCheckEmployee();
    } else {
      this.messageSubject.next(null);
      this.journeySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorDeleteCheck = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    this.journeySubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Send Checks Report
  // ==========================================================================================

  public sendChecksReport(employeeID: number, month: Month): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.checkHttp.sendChecksReport$(employeeID, month).subscribe({
      next: this.nextSendChecksReport,
      error: this.errorSendChecksReport,
    });
  }

  private nextSendChecksReport = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.messageSubject.next('OK sent email');
      this.snackbars.successSentReportByEmail();
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorSendChecksReport = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureInvalidField();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Pagination Links
  // ==========================================================================================

  public getPaginationLinks$(): Observable<PaginationLinks> {
    return this.paginationLinksSubject.asObservable();
  }
}
