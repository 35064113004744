import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { format, parseISO } from 'date-fns';

export class CheckWidgetForm {
  public registerCheckForm: UntypedFormGroup;

  private fb: UntypedFormBuilder = new UntypedFormBuilder();
  private registerCheckFormSkeleton;

  constructor() {
    this.registerCheckFormSkeleton = {
      type: ['WEB', Validators.required],
      startDate: ['', Validators.required],
    };
    this.registerCheckForm = this.fb.group(this.registerCheckFormSkeleton);
  }

  get startDate(): AbstractControl {
    return this.registerCheckForm.get('startDate');
  }

  public prepateDataToSend(): void {
    const now = new Date().toISOString().slice(0, -1);
    const date = format(parseISO(now), 'yyyy-MM-dd HH:mm:ss');
    this.startDate.setValue(date);
  }
}
