import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { WidgetsModule } from './widgets/widgets.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ComponentsModule],
  exports: [
    ReactiveFormsModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    WidgetsModule,
  ],
})
export class SharedModule {}
