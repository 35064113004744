import { QaroniCacheCollection } from '@qaroni-core/types/qaroni-cache/qaroni-cache-collection';
import { Workplace } from './workplace';

export class WorkplacesCache extends QaroniCacheCollection<Workplace> {
  public valid(workplace: Workplace): boolean {
    return Number.isInteger(workplace?.workplaceId);
  }

  public findIndex(workplace: Workplace): number {
    return this.get()?.findIndex(
      w => w?.workplaceId === workplace?.workplaceId
    );
  }

  // TODO: study what do with params
  public canReturn(companyID: number): boolean {
    return this.length() > 0 &&
      Number.isInteger(companyID) &&
      this.first()?.companyId === companyID
      ? true
      : false;
  }

  public canApi(companyID: number): boolean {
    return !this.canReturn(companyID) && !this.flying ? true : false;
  }
}
