import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanySnackbars } from '../types/company-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class CompanySnackbarsService {
  constructor(private snackbar: MatSnackBar) {}

  public successRegister(): void {
    this.snackbar.open(
      CompanySnackbars.successRegister.message,
      CompanySnackbars.successRegister.closeBtn,
      CompanySnackbars.successRegister.config
    );
  }

  public successUpdated(): void {
    this.snackbar.open(
      CompanySnackbars.successUpdated.message,
      CompanySnackbars.successUpdated.closeBtn,
      CompanySnackbars.successUpdated.config
    );
  }

  public successAddressUpdated(): void {
    this.snackbar.open(
      CompanySnackbars.successAddressUpdated.message,
      CompanySnackbars.successAddressUpdated.closeBtn,
      CompanySnackbars.successAddressUpdated.config
    );
  }

  public failureValidationError(): void {
    this.snackbar.open(
      CompanySnackbars.failureValidationError.message,
      CompanySnackbars.failureValidationError.closeBtn,
      CompanySnackbars.failureValidationError.config
    );
  }
}
