import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/auth/services/o-auth-storage.service';
import { OAuth } from '@qaroni-app/auth/types/o-auth';
import { CheckWidgetService } from '@qaroni-app/check/services/check-widget.service';
import { CheckService } from '@qaroni-app/check/services/check.service';
import { Check } from '@qaroni-app/check/types/check';
import { Journey } from '@qaroni-app/check/types/journey';
import { WorkersService } from '@qaroni-app/workers/services/workers.service';
import { UserRole } from '@qaroni-app/workers/types/user-role';
import { CheckWidgetCardDesignConfig } from '@qaroni-core/config/app/check-widget-card-design.config';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';
import { format, parseISO } from 'date-fns';
import { interval, Observable, Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { CheckWidgetForm } from './check-widget-form.class';

@Component({
  selector: 'qaroni-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckComponent
  extends CheckWidgetForm
  implements OnInit, OnDestroy
{
  @Input()
  get hideDragButton(): boolean {
    return this._hideDragButton;
  }
  set hideDragButton(value: BooleanInput) {
    this._hideDragButton = coerceBooleanProperty(value);
  }
  private _hideDragButton: boolean = false;

  public cardDesign;
  public journey: Journey;
  public infoTooltip: string;
  public isLoading$: Observable<boolean>;

  private companyID: number;
  private employeeID: number;
  private oAuth: OAuth;
  private userRole$: Observable<UserRole>;
  private journey$: Observable<Journey>;
  private journeyTable$: Observable<Journey>;
  private check$: Observable<Check>;
  private minutes$: Observable<number>;
  private subs: Subscription = new Subscription();
  private subMinutes: Subscription;

  constructor(
    private route: ActivatedRoute,
    private progressBar: ProgressBarService,
    private checkService: CheckService,
    private checkWidgetService: CheckWidgetService,
    private workersService: WorkersService,
    private oAuthStorageService: OAuthStorageService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
    this.cardDesign = CheckWidgetCardDesignConfig.deactive;
    this.infoTooltip = $localize`No hay fichajes en la jornada de hoy`;
    this.oAuth = this.oAuthStorageService.get();
    this.minutes$ = interval(1000 * 60);
    this.isLoading$ = this.progressBar.getProgressBar$().pipe(shareReplay(1));
    this.userRole$ = this.workersService.getUserRole$().pipe(shareReplay(1));
    this.journey$ = this.checkWidgetService.getJourney$().pipe(shareReplay(1));
    this.journeyTable$ = this.checkService.getJourney$().pipe(
      filter(journey => this.journey.dayCheck === journey?.dayCheck),
      shareReplay(1)
    );
    this.check$ = this.checkWidgetService.getCheck$().pipe(shareReplay(1));
  }

  ngOnInit(): void {
    this.subs.add(this.userRole$.subscribe(this.getUserRole));
    this.subs.add(this.route.paramMap.subscribe(this.getParamMap));
    this.subs.add(this.journey$.subscribe(this.getJourney));
    this.subs.add(this.journeyTable$.subscribe(this.getJourneyTable));
    this.subs.add(this.check$.subscribe(this.getCheck));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getParamMap = (paramMap: ParamMap): void => {
    if (paramMap.has('companyID') && this.oAuth?.userId) {
      this.companyID = parseInt(paramMap.get('companyID'));
      this.workersService.getUserRole(this.companyID, this.oAuth.userId);
    }
  };

  private getUserRole = (userRole: UserRole): void => {
    if (userRole?.employeeId) {
      this.employeeID = userRole.employeeId;
      const now = new Date().toISOString().slice(0, -1);
      const params: Params = {
        currentDate: format(parseISO(now), 'yyyy-MM-dd HH:mm:ss'),
      };
      this.checkWidgetService.getJourney(this.employeeID, params);
    }
  };

  public onCheck(): void {
    this.prepateDataToSend();
    this.checkWidgetService.toggleCheck(
      this.employeeID,
      this.registerCheckForm.value
    );
  }

  private getJourney = (journey: Journey): void => {
    if (!journey) {
      return;
    }

    this.journey = journey;
    this._cdr.markForCheck();

    if (this.journey?.isOpen) {
      this.subMinutes = this.minutes$.subscribe(this.getMinutes);
      this.subs.add(this.subMinutes);
    } else if (this.subMinutes) {
      this.subMinutes.unsubscribe();
    }

    this.setCheckList();

    this.cardDesign = this.journey?.isOpen
      ? CheckWidgetCardDesignConfig.active
      : CheckWidgetCardDesignConfig.deactive;
  };

  private getJourneyTable = (journey: Journey): void => {
    if (!journey) {
      return;
    }

    this.journey = journey;
    this._cdr.markForCheck();

    this.setCheckList();
  };

  private getMinutes = (minutes: number): void => {
    this.journey.totalJourney += 1;
    this._cdr.markForCheck();
  };

  private getCheck = (check: Check): void => {};

  private setCheckList(): void {
    this.infoTooltip = $localize`No hay fichajes en la jornada de hoy`;
    if (this.journey?.checkList?.length) {
      this.infoTooltip = ``;
      this.journey?.checkList?.forEach((check, index) => {
        this.infoTooltip = `
        ${this.infoTooltip}
        ${index + 1}) ${check}
        `;
      });
    }
    this._cdr.markForCheck();
  }
}
