import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@qaroni-shared/components/components.module';
import { PipesModule } from '@qaroni-shared/pipes/pipes.module';
import { CheckComponent } from './check/check.component';
import { CompanyWidgetComponent } from './company-widget/company-widget.component';
import { ScoreboardWidgetComponent } from './scoreboard-widget/scoreboard-widget.component';
import { WorkplacesWidgetComponent } from './workplaces-widget/workplaces-widget.component';

const widgets: any[] = [
  CheckComponent,
  CompanyWidgetComponent,
  ScoreboardWidgetComponent,
  WorkplacesWidgetComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    PipesModule,
    RouterModule,
  ],
  declarations: [widgets],
  exports: [widgets],
})
export class WidgetsModule {}
