import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';
import { WorkplaceAddress } from '../types/workplace-address';
import { WorkplaceRegisterJson } from '../types/workplace-register-json';
import { WorkplaceUpdateJson } from '../types/workplace-update-json';

@Injectable({
  providedIn: 'root',
})
export class WorkplaceHttpService {
  constructor(private http: HttpClient) {}

  public registerWorkplace$(
    companyID: number,
    workplaceRegisterJson: WorkplaceRegisterJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/workplaces`;

    return this.http.post(url, workplaceRegisterJson, { observe: 'response' });
  }

  public getWorkplaces$(
    companyID: number,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/workplaces`;

    return this.http.get(url, { observe: 'response', params });
  }

  public getWorkplace$(
    companyID: number,
    workplaceID: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/workplaces/${workplaceID}`;

    return this.http.get(url, { observe: 'response' });
  }

  public updateWorkplace$(
    companyID: number,
    workplaceID: number,
    updateWorkplaceJson: WorkplaceUpdateJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/workplaces/${workplaceID}`;

    return this.http.patch(url, updateWorkplaceJson, { observe: 'response' });
  }

  public updateWorkplaceAddress$(
    companyID: number,
    workplaceID: number,
    address: WorkplaceAddress
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/workplaces/${workplaceID}/addresses`;

    return this.http.patch(url, address, { observe: 'response' });
  }

  public removeWorkplace$(
    companyID: number,
    workplaceID: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/workplaces/${workplaceID}`;

    return this.http.delete(url, { observe: 'response' });
  }
}
