import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { WorkplaceService } from '@qaroni-app/workplace/services/workplace.service';
import { Workplace } from '@qaroni-app/workplace/types/workplace';
import { WorkplaceStatusEnum } from '@qaroni-app/workplace/types/workplace-status.enum';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-workplaces-widget',
  templateUrl: './workplaces-widget.component.html',
  styleUrls: ['./workplaces-widget.component.scss'],
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkplacesWidgetComponent implements OnInit, OnDestroy {
  public workplaces = signal<Workplace[]>([]);
  public workplaces$ = this.workplaceService.getWorkplaces$();

  private companyID: number;

  private paramMap$: Observable<ParamMap> = this.route.paramMap.pipe(
    shareReplay(1)
  );

  private subs: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workplaceService: WorkplaceService
  ) {}

  ngOnInit(): void {
    this.subs.add(this.paramMap$.subscribe(this.getParamMap));
    this.subs.add(this.workplaces$.subscribe(this.getWorkplaces));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  goToCreateWorkplace() {
    if (this.companyID) {
      this.router.navigate([`/companies/${this.companyID}/workplaces/add`]);
    }
  }

  goToWorkplacesList() {
    if (this.companyID) {
      this.router.navigate([`/companies/${this.companyID}/details`]);
    }
  }

  private getParamMap = (paramMap: ParamMap): void => {
    if (paramMap.has('companyID')) {
      this.companyID = parseInt(paramMap.get('companyID'));
      const params: Params = { status: WorkplaceStatusEnum.ACTIVE };
      this.workplaceService.getWorkplaces(this.companyID, params);
    }
  };

  private getWorkplaces = (workplaces: Workplace[]): void => {
    if (workplaces?.length) {
      this.workplaces.set(workplaces);
    }
  };
}
