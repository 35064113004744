import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config/snackbars/snackbar.config';

export const CheckSnackbars = {
  successRegisterCheck: {
    message: `Fichaje registrado con éxito!`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successSentReportByEmail: {
    message: `Se ha enviado un correo electrónico con el reporte de fichajes`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successDeleteCheckEmployee: {
    message: `Fichaje eliminado con éxito!`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureInvalidField: {
    message: `Los datos enviados no son correctos`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureEmployeeInvalidCredentials: {
    message: `No tiene permiso para acceder a este recurso`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureWrongHours: {
    message: `La Hora de Cierre no puede ser menor que la Hora de Inicio`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureEmployeeNotWithInWorkplaceAccessRadius: {
    message: `No se encuentra dentro del radio de acceso al centro de trabajo`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureCheckDateCollidesWithAnExistingOne: {
    message: `La fecha del fichaje coincide con una existente. Inténtelo de nuevo`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureCheckCalendarIsClosed: {
    message: `El calendario está cerrado`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureEmployeeOnLeave: {
    message: `Empleado de baja en esta fecha, el fichaje no se puede editar`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
};
