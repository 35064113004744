import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Check } from '../types/check';
import { Journey } from '../types/journey';
import { JourneyCache } from '../types/journey-cache.class';
import { CheckWidgetHttpService } from './check-widget-http.service';
import { CheckWidgetSnackbarsService } from './check-widget-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class CheckWidgetService {
  private journey: JourneyCache = new JourneyCache();

  protected readonly checkSubject = new Subject<Check>();
  protected readonly journeySubject = new Subject<Journey>();

  constructor(
    private commonsHttp: CommonsHttpService,
    private checkWidgetHttp: CheckWidgetHttpService,
    private snackbars: CheckWidgetSnackbarsService
  ) {}

  // ==========================================================================================
  // Toggle Check
  // ==========================================================================================

  public getCheck$(): Observable<Check> {
    return this.checkSubject.asObservable();
  }

  public toggleCheck(employeeID: number, check: Check): void {
    this.checkWidgetHttp.toggleCheck$(employeeID, check).subscribe({
      next: this.nextToggleCheck,
      error: this.errorToggleCheck,
    });
  }

  private nextToggleCheck = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus201(data) &&
      data?.body?.result?.length
    ) {
      const check: Check = data.body.result[0];
      this.checkSubject.next(check);
      if (data?.body?.included) {
        const journey: Journey = data.body.included;
        this.journeySubject.next(journey);
      }
    } else {
      this.checkSubject.next(null);
      this.journeySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorToggleCheck = (error: HttpErrorResponse): void => {
    this.checkSubject.next(null);
    this.journeySubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureInvalidField();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0008')) {
      this.snackbars.failureEmployeeInvalidCredentials();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0020')) {
      this.snackbars.failureEmployeeNotWithInWorkplaceAccessRadius();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0030')) {
      this.snackbars.failureCheckCalendarIsClosed();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Journey
  // ==========================================================================================

  public getJourney$(): Observable<Journey> {
    return this.journeySubject.asObservable();
  }

  public getJourney(employeeID: number, params: Params): void {
    if (this.journey.canReturn(employeeID)) {
      this.journeySubject.next(this.journey.get());
    } else if (this.journey.canApi(employeeID)) {
      this.journey.startToFly();
      this.checkWidgetHttp
        .getJourney$(employeeID, params)
        .pipe(finalize(() => this.journey.endFlying()))
        .subscribe({
          next: this.nextGetJourney,
          error: this.errorGetJourney,
        });
    }
  }

  private nextGetJourney = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const journey: Journey = data.body.result[0];
      this.journeySubject.next(journey);
    } else {
      this.journeySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetJourney = (error: HttpErrorResponse): void => {
    this.journeySubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };
}
