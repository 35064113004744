export const CheckWidgetCardDesignConfig = {
  active: {
    textButton: $localize`Detener`,
    colorButton: `danger`,
    iconButton: `stop_circle`,
    classBgStatus: `qaroni-bg-primary50`,
  },
  deactive: {
    textButton: $localize`Iniciar`,
    colorButton: `blue`,
    iconButton: `play_circle_filled`,
    classBgStatus: `qaroni-bg-danger-light`,
  },
};
