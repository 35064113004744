import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/auth/services/o-auth-storage.service';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { Workplace } from '../types/workplace';
import { WorkplaceAddress } from '../types/workplace-address';
import { WorkplaceCache } from '../types/workplace-cache.class';
import { WorkplaceRegisterJson } from '../types/workplace-register-json';
import { WorkplaceUpdateJson } from '../types/workplace-update-json';
import { WorkplacesCache } from '../types/workplaces-cache.class';
import { WorkplaceHttpService } from './workplace-http.service';
import { WorkplaceSnackbarsService } from './workplace-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class WorkplaceService {
  protected readonly workplacesSubject = new BehaviorSubject<Workplace[]>(null);
  private workplaces: WorkplacesCache = new WorkplacesCache();

  protected readonly workplaceSubject = new Subject<Workplace>();
  private workplace: WorkplaceCache = new WorkplaceCache();
  // TODO: Think and study when remove method will call

  protected readonly messageSubject = new Subject<string>();

  constructor(
    private commonsHttp: CommonsHttpService,
    private workplaceHttpService: WorkplaceHttpService,
    private oAuthStorage: OAuthStorageService,
    public snackbars: WorkplaceSnackbarsService
  ) {}

  // TODO: Check if can't use and remove this shit of message
  public getMessage$() {
    return this.messageSubject.asObservable().pipe(shareReplay(1));
  }

  // ==========================================================================================
  // Get Workplaces
  // ==========================================================================================

  public getWorkplaces$() {
    return this.workplacesSubject.asObservable().pipe(shareReplay(1));
  }

  public resetWorkplacesCache() {
    return this.workplaces.empty();
  }

  public getWorkplaces(companyID: number, params?: Params): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    if (this.workplaces.canReturn(companyID)) {
      this.workplacesSubject.next(this.workplaces.get());
    } else if (this.workplaces.canApi(companyID)) {
      this.workplaces.startToFly();
      this.workplaceHttpService
        .getWorkplaces$(companyID, params)
        .pipe(finalize(() => this.workplaces.endFlying()))
        .subscribe({
          next: this.nextGetWorkplaces,
          error: this.errorGetWorkplaces,
        });
    }
  }

  private nextGetWorkplaces = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.workplaces.set(data.body.result);
      this.workplacesSubject.next(this.workplaces.get());
    } else {
      this.workplacesSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetWorkplaces = (error: HttpErrorResponse): void => {
    this.workplacesSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Register Workplace
  // ==========================================================================================

  public getWorkplace$() {
    return this.workplaceSubject.asObservable().pipe(shareReplay(1));
  }

  public resetWorkplaceCache() {
    return this.workplace.empty();
  }

  public registerWorkplace(
    workplaceRegisterJson: WorkplaceRegisterJson,
    companyID: number
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.workplaceHttpService
      .registerWorkplace$(companyID, workplaceRegisterJson)
      .subscribe({
        next: this.nextRegisterWorkplace,
        error: this.errorRegisterWorkplace,
      });
  }

  private nextRegisterWorkplace = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus201(data) &&
      data?.body?.result?.length
    ) {
      this.workplace.set(data.body.result[0]);
      this.workplaces.add(this.workplace.get());
      this.workplaceSubject.next(this.workplace.get());
    } else {
      this.workplaceSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorRegisterWorkplace = (error: HttpErrorResponse): void => {
    this.workplaceSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureInvalidTimezone();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Workplace
  // ==========================================================================================

  public getWorkplace(companyID: number, workplaceID: number): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    if (this.workplace.canReturn(companyID, workplaceID)) {
      this.workplaceSubject.next(this.workplace.get());
    } else if (this.workplace.canApi(companyID, workplaceID)) {
      this.workplace.startToFly();
      this.workplaceHttpService
        .getWorkplace$(companyID, workplaceID)
        .pipe(finalize(() => this.workplace.endFlying()))
        .subscribe({
          next: this.nextGetWorkplace,
          error: this.errorGetWorkplace,
        });
    }
  }

  private nextGetWorkplace = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.workplace.set(data.body.result[0]);
      this.workplaceSubject.next(this.workplace.get());
    } else {
      this.workplaceSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetWorkplace = (error: HttpErrorResponse): void => {
    this.workplaceSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Workplace
  // ==========================================================================================

  public updateWorkplace(
    companyID: number,
    workplaceID: number,
    updateWorkplaceJson: WorkplaceUpdateJson
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.workplaceHttpService
      .updateWorkplace$(companyID, workplaceID, updateWorkplaceJson)
      .subscribe({
        next: this.nextUpdateWorkplace,
        error: this.errorUpdateWorkplace,
      });
  }

  private nextUpdateWorkplace = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.workplace.set(data.body.result[0]);
      this.workplaces.update(this.workplace.get());
      this.messageSubject.next('Ok updated workplace');
      this.workplaceSubject.next(this.workplace.get());
    } else {
      this.messageSubject.next(null);
      this.workplaceSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdateWorkplace = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    this.workplaceSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Workplace Address
  // ==========================================================================================

  public updateWorkplaceAddress(
    companyID: number,
    workplaceID: number,
    address: WorkplaceAddress
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.workplaceHttpService
      .updateWorkplaceAddress$(companyID, workplaceID, address)
      .subscribe({
        next: this.nextUpdateWorkplaceAddress,
        error: this.errorUpdateWorkplaceAddress,
      });
  }

  private nextUpdateWorkplaceAddress = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.workplace.set(data.body.result[0]);
      this.workplaces.update(this.workplace.get());
      this.workplaceSubject.next(this.workplace.get());
    } else {
      this.workplaceSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdateWorkplaceAddress = (error: HttpErrorResponse): void => {
    this.workplaceSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Remove Workplace
  // ==========================================================================================

  public removeWorkplace(companyID: number, workplaceID: number): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.workplaceHttpService
      .removeWorkplace$(companyID, workplaceID)
      .subscribe({
        next: this.nextRemoveWorkplace,
        error: this.errorRemoveWorkplace,
      });
  }

  private nextRemoveWorkplace = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.workplaces.empty();
      this.workplace.empty();
      this.workplaceSubject.next(this.workplace.get());
      this.messageSubject.next('OK deleted workplace');
    } else {
      this.workplaceSubject.next(null);
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorRemoveWorkplace = (error: HttpErrorResponse): void => {
    this.workplaceSubject.next(null);
    this.messageSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };
}
