import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[qaroniClickStopPropagation]',
})
export class ClickStopPropagationDirective {
  @HostListener('click', ['$event'])
  public onClick(event: PointerEvent): void {
    event?.stopPropagation();
    event?.preventDefault();
  }
}
