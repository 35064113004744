import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum WorkplaceStatusEnum {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
}

export type WorkplaceStatusType =
  | WorkplaceStatusEnum.CREATED
  | WorkplaceStatusEnum.ACTIVE
  | WorkplaceStatusEnum.INACTIVE
  | WorkplaceStatusEnum.PENDING
  | WorkplaceStatusEnum.CANCELLED;

export const WorkplaceStatusArray = [
  WorkplaceStatusEnum.CREATED,
  WorkplaceStatusEnum.ACTIVE,
  WorkplaceStatusEnum.INACTIVE,
  WorkplaceStatusEnum.PENDING,
  WorkplaceStatusEnum.CANCELLED,
];

export const WorkplaceStatusInfo: QaroniEnumInformation<WorkplaceStatusEnum> = {
  [WorkplaceStatusEnum.CREATED]: {
    name: $localize`Creado`,
  },
  [WorkplaceStatusEnum.ACTIVE]: {
    name: $localize`Activo`,
  },
  [WorkplaceStatusEnum.INACTIVE]: {
    name: $localize`Inactivo`,
  },
  [WorkplaceStatusEnum.PENDING]: {
    name: $localize`Pendiente`,
  },
  [WorkplaceStatusEnum.CANCELLED]: {
    name: $localize`Cancelado`,
  },
};
