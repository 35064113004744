import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickStopPropagationDirective } from './click-stop-propagation/click-stop-propagation.directive';
import { FilesDragAndDropDirective } from './files-drag-and-drop/files-drag-and-drop.directive';

const directives = [ClickStopPropagationDirective, FilesDragAndDropDirective];

@NgModule({
  declarations: [directives],
  imports: [CommonModule],
  exports: [directives],
})
export class DirectivesModule {}
