import { QaroniCache } from '@qaroni-core/types/qaroni-cache/qaroni-cache';
import { Company } from './company';

export class CompanyCache extends QaroniCache<Company> {
  public canReturn(companyID: number): boolean {
    return this.get() &&
      Number.isInteger(companyID) &&
      this.get().companyId === companyID
      ? true
      : false;
  }

  public canApi(companyID: number): boolean {
    return !this.canReturn(companyID) && !this.flying ? true : false;
  }
}
