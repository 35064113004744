import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CompanyService } from '@qaroni-app/company/services/company.service';
import { Company } from '@qaroni-app/company/types/company';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-company-widget',
  templateUrl: './company-widget.component.html',
  styleUrls: ['./company-widget.component.scss'],
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyWidgetComponent implements OnInit, OnDestroy {
  public company = signal<Company | null>(null);

  private company$: Observable<Company> = this.companyService
    .getCompany$()
    .pipe(shareReplay(1));

  private paramMap$: Observable<ParamMap> = this.route.paramMap.pipe(
    shareReplay(1)
  );

  private subs: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.subs.add(this.company$.subscribe(this.getCompany));
    this.subs.add(this.paramMap$.subscribe(this.getParamMap));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get goToUpdateCompany(): string[] {
    return [`/companies/${this.company().companyId}/update`];
  }

  private getCompany = (company: Company) => {
    if (company?.companyId) {
      this.company.set(company);
    }
  };

  private getParamMap = (paramMap: ParamMap): void => {
    if (paramMap.has('companyID')) {
      const companyID = parseInt(paramMap.get('companyID'));
      this.companyService.getCompany(companyID);
    }
  };
}
