import { QaroniCache } from '@qaroni-core/types/qaroni-cache/qaroni-cache';
import { Journey } from './journey';

export class JourneyCache extends QaroniCache<Journey> {
  public canReturn(employeeID: number): boolean {
    return this.get() &&
      Number.isInteger(employeeID) &&
      this.get().employeeId === employeeID
      ? true
      : false;
  }

  public canApi(employeeID: number): boolean {
    return !this.canReturn(employeeID) && !this.flying ? true : false;
  }
}
