<mat-card
  @enterFadeIn
  class="qaroni-bg-surface qaroni-surface-border mat-elevation-z0"
  *ngIf="workplaces()">
  <div class="mat-card-header">
    <mat-card-title i18n>Centros de Trabajo</mat-card-title>
  </div>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div
      class="grid grid-flow-row grid-cols-6 gap-3"
      *ngIf="workplaces$ | async">
      <div class="col-span-6 md:col-span-3 lg:col-span-2">
        <qaroni-workplaces-map
          [workplacesList]="workplaces()"
          [height]="'191.93px'"></qaroni-workplaces-map>
      </div>

      <div class="col-span-6 md:col-span-3 lg:col-span-4">
        <div
          *ngFor="
            let workplace of workplaces() | slice: 0 : 2;
            first as isFirst;
            last as isLast
          ">
          <qaroni-card-workplace [workplace]="workplace" [first]="isFirst">
          </qaroni-card-workplace>
          <mat-divider class="my-3"></mat-divider>
        </div>
        <div class="flex w-full items-center justify-between gap-3">
          <a
            class="qaroni-link-on-surface flex"
            (click)="goToWorkplacesList()"
            (keypress)="goToWorkplacesList()">
            <span class="truncate" i18n>Ver todos</span>
          </a>

          <div class="flex-1">
            <a
              class="qaroni-link-on-surface"
              (click)="goToCreateWorkplace()"
              (keypress)="goToCreateWorkplace()"
              i18n-matTooltip
              matTooltip="Agregar centro de trabajo">
              <div class="flex items-center justify-end gap-1">
                <mat-icon>add_business</mat-icon
                ><span i18n>Agregar centro de trabajo</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
